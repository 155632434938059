<template>
    <SimpleTable formclass="Exam/form" endpoint="exams" :aditionalColumns="headers" inlineToggle hideDelete>
        <template>
            <h1>Cadastro de Exames</h1>
            <v-divider class="mb-4"></v-divider>
        </template>
    </SimpleTable>
</template>

<script>
import SimpleTable from "@/components/core/SimpleTable";
export default {
    components: {
        SimpleTable,
    },
    data() {
        return {
            headers: [
                { text: this.$t("Habilitado"), value: "enable", align: "right", sortable: false },
                { text: this.$t("Preço"), value: "price", align: "right", sortable: true },
                { text: this.$t("Nome"), value: "name", align: "right", sortable: true },
            ],
        };
    },
};
</script>